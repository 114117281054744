import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "accessing-theme-values"
    }}>{`Accessing theme values`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <h2 {...{
      "id": "first-how-are-the-theme-values-stored"
    }}>{`First, How are the theme values stored?`}</h2>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`UiView`}</inlineCode>{` component creates CSS Variables from the theme values, these variables are used across the library to retrieve the current value for a specific token.
If you inspect your App in the `}<inlineCode parentName="p">{`html`}</inlineCode>{` element you'll see a lot of variables defined into `}<inlineCode parentName="p">{`:root`}</inlineCode>{`. If you decide to enable the light theme, then
you will see the class: `}<inlineCode parentName="p">{`light`}</inlineCode>{` added to the `}<inlineCode parentName="p">{`html`}</inlineCode>{` element, this is how the theme changes the values of the CSS variables
based on what theme is enabled.`}</p>
    <h3 {...{
      "id": "react-context"
    }}>{`React context`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`UiView`}</inlineCode>{` component stores the theme into the `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/view/src/ui-view.tsx#L80"
      }}>{`ThemeContext`}</a>{`, this is accessible through useContext hook at any place of the application.`}</p>
    <h2 {...{
      "id": "use-values-from-client-applications"
    }}>{`Use values from client applications`}</h2>
    <h3 {...{
      "id": "css-1"
    }}>{`CSS`}</h3>
    <p>{`So, now that you know where the values are actually stored in the CSS, using any value is as simple as just using that
CSS variable from any place in your app. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import styled from 'styled-components';

const SomeWrapper = styled.div\`
  background: var(--primary-token_100);
  font-size: var(--texts-large);
  padding: var(--spacing-four);
\`;

export const myComponent = () => {
  return (
    <SomeWrapper>
      <p>The content</p>
    </SomeWrapper>
  );
};
`}</code></pre>
    <p>{`The variables have the following format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`--{'inverse-' || ''}-{category}-{token}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`inverse`}</inlineCode>{` name specifies that this is the value of the inversed theme, e.g. if dark is enabled then the inverse is the light theme and viceversa.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`category`}</inlineCode>{` means the ColorCategory of the theme property.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`token`}</inlineCode>{` is the token level used in each color category in the theme.`}</p>
    <h3 {...{
      "id": "js-usecontext"
    }}>{`JS (useContext)`}</h3>
    <p>{`There are a few functions exported from the foundation package that can help you get a specific token from the theme.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`-getThemeColor();

// @deprecated
-getHeadingSize();

// @deprecated
-getTextSize();

// @deprecated
-getSpacingSize();
`}</code></pre>
    <p>{`We've deprecated most of these helper functions as you should try to rely as much as possible on CSS to wrap the correct theme value,
however there are a few use cases where needing the theme color in JS is useful, such as setting the color of a Google Chart, the colors
are set via a config object.`}</p>
    <p>{`So, this is an example of how you would access it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { ThemeContext, getThemeColor, ColorCategories, ColorTokens } from '@uireact/foundation';

export const myComponent = () => {
  const themeContext = useContent(ThemeContext);
  const color = getThemeColor(
    themeContext.theme,
    themeContext.selectedTheme,
    ColorCategories.primary,
    ColorTokens.token_100,
    false
  );

  return <SomeComponent color={color} />;
};
`}</code></pre>
    <p>{`The last attribute of `}<inlineCode parentName="p">{`getThemeColor`}</inlineCode>{` is an `}<inlineCode parentName="p">{`inverse`}</inlineCode>{` boolean, in case you need the inverse coloration.`}</p>
    <iframe src="https://giphy.com/embed/pynZagVcYxVUk" width="300" height="300" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      